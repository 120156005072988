export const getPageElements = (type = null) => {
    const p = [
        {
            type: "numericPrediction",
            icon: "123",
            text: "Prediction",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['registrationForm', 'poll', 'imageChoice', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes'],
            extraPagesBefore: [],
            unique: false,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['registrationForm', 'numericPrediction', 'submitButton'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "registrationForm",
            icon: "how_to_reg",
            text: "Registration",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['numericPrediction', 'poll', 'imageChoice', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes', 'registrationForm', 'submitButton'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: true,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['registrationForm'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "imageChoice",
            icon: "collections",
            text: "Image Choice",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['registrationForm', 'numericPrediction', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['imageChoice', 'registrationForm', 'submitButton'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "mvp",
            icon: "sports_gymnastics",
            text: "MVP",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['registrationForm', 'poll', 'imageChoice', 'soccerLineUpSelector', 'wheelOfPrizes'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['registrationForm', 'submitButton', 'mvp'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "soccerLineupSelector",
            icon: "sports_soccer",
            text: "Line-Up Selector",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['registrationForm', 'numericPrediction', 'poll', 'imageChoice', 'mvp', 'wheelOfPrizes'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['soccerLineupSelector', 'registrationForm'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "poll",
            icon: "how_to_vote",
            text: "Poll",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['registrationForm', 'numericPrediction', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['poll', 'registrationForm', 'submitButton'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "wheelOfPrizes",
            icon: "interests",
            text: "Wheel Of Prizes",
            allowedPageTypes: ['normal'],
            allowedOnLocked: true,
            conflictingElementTypes: ['registrationForm', 'numericPrediction', 'poll', 'imageChoice', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes', 'submitButton'],
            hasWizard: true,
            extraPagesBefore: ['registrationForm', 'wheelOfPrizesPreview'],
            unique: true,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['wheelOfPrizes', 'registrationForm'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "scratch",
            icon: "casino",
            text: "Scratch To Win",
            allowedPageTypes: ['normal'],
            allowedOnLocked: true,
            conflictingElementTypes: ['registrationForm', 'numericPrediction', 'poll', 'imageChoice', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes', 'submitButton', 'scratch'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: true,
            allowedNonDecorativeFeaturesOnCampaign: ['scratch', 'registrationForm'],
            nonDraggablePages: [],
            nonDeletablePages: [],
        },
        {
            type: "submitButton",
            icon: "ads_click",
            text: "Submit",
            allowedPageTypes: ['normal'],
            allowedOnLocked: false,
            conflictingElementTypes: ['registrationForm', 'submitButton', 'wheelOfPrizes', 'scratch'],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        },
        {
            type: "image",
            icon: "image",
            text: "Image",
            allowedPageTypes: ['normal', 'endPage', 'expiredPage'],
            allowedOnLocked: true,
            conflictingElementTypes: [],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        },
        {
            type: "spacer",
            icon: "expand",
            text: "Spacer",
            conflictingElementTypes: [],
            hasWizard: false,
            allowedPageTypes: ['normal', 'endPage', 'expiredPage'],
            allowedOnLocked: true,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        },
        {
            type: "link",
            icon: "link",
            text: "Link",
            allowedPageTypes: ['normal', 'endPage', 'expiredPage'],
            allowedOnLocked: true,
            conflictingElementTypes: [],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        },
        {
            type: "iframe",
            icon: "filter_frames",
            text: "IFrame",
            allowedPageTypes: ['normal', 'endPage', 'expiredPage'],
            allowedOnLocked: true,
            conflictingElementTypes: [],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        },
        {
            type: "text",
            icon: "html",
            text: "Text",
            allowedPageTypes: ['normal', 'endPage', 'expiredPage'],
            allowedOnLocked: true,
            conflictingElementTypes: [],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        },
        {
            type: "slideShow",
            icon: "web_stories",
            text: "Slide Show",
            allowedPageTypes: ['normal', 'endPage', 'expiredPage'],
            allowedOnLocked: true,
            conflictingElementTypes: [],
            hasWizard: false,
            extraPagesBefore: [],
            unique: false,
            isCampaignType: false
        }
    ];
    if (type) {
        return p.find(el => el.type === type)
    }
    return p
}
const extraCampaignTypes = [
    {
        type: "wheelOfPrizesPreview",
        icon: "interests",
        text: "Wheel Of Prizes (Fake Spin)",
        allowedPageTypes: ['normal'],
        allowedOnLocked: false,
        conflictingElementTypes: ['registrationForm', 'numericPrediction', 'poll', 'imageChoice', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes', 'submitButton'],
        hasWizard: false,
        extraPagesBefore: [],
        unique: true,
        isCampaignType: true,
        allowedNonDecorativeFeaturesOnCampaign: ['wheelOfPrizes', 'registrationForm'],
        nonDraggablePages: ['registrationForm', 'wheelOfPrizes', 'wheelOfPrizesPreview'],
        nonDeletablePages: ['registrationForm', 'wheelOfPrizes', 'wheelOfPrizesPreview']
    },
    {
        type: "custom",
        icon: "interests",
        text: "Custom Type",
        allowedPageTypes: ['normal'],
        allowedOnLocked: false,
        conflictingElementTypes: [],
        hasWizard: false,
        extraPagesBefore: [],
        unique: true,
        isCampaignType: true,
        allowedNonDecorativeFeaturesOnCampaign: ['All Non Decorative Features', 'registrationForm', 'numericPrediction', 'poll', 'imageChoice', 'mvp', 'soccerLineUpSelector', 'wheelOfPrizes', 'scratch', 'ugc'],
        nonDraggablePages: [],
        nonDeletablePages: []
    },
    {
        type: "ugc",
        icon: "web_stories",
        text: "User Generated Content (Beta)",
        allowedPageTypes: ['endPage'],
        allowedOnLocked: false,
        conflictingElementTypes: [],
        hasWizard: false,
        extraPagesBefore: [],
        unique: true,
        isCampaignType: true,
        allowedNonDecorativeFeaturesOnCampaign: ['ugc'],
        nonDraggablePages: [],
        nonDeletablePages: []
    },
];

/* 
    Function that fetches all the necessary page types with their elements when creating a new campaign
    @param type -> The type of campaign
    @returns array -> index 0 = All campaign types, index 1 = Decorative Features
 */
export const getTypesAndElements = (type=null, campaignType=null, removeExtraTypes= false) => {
    const [typeElements, decorativeElements] = getPageElements().reduce((acc, element) =>{
		if(element.isCampaignType){
			acc[0] = [...acc[0], element]
		}else{
			acc[1] = [...acc[1], element]
		}
		return acc;
	}, [[],[]])
    typeElements.push(...extraCampaignTypes);

    if(type){
        const f = typeElements.find(campaignType => campaignType.type === type);
        if(f) return f;
        const f2 = decorativeElements.find(campaignType => campaignType.type === type);
        if(f2) return f2;
    }
    let out = [typeElements, decorativeElements];
    if(campaignType){
        const currentCampaignType = typeElements.find( el => el.type === campaignType)
        if(!currentCampaignType) return out;
        const allowedSubmissionElements = typeElements.filter((el) => currentCampaignType.allowedNonDecorativeFeaturesOnCampaign.includes(el.type))
        if(removeExtraTypes){
            allowedSubmissionElements.splice(-2);
        }
        return [allowedSubmissionElements, decorativeElements];
    }
    return out
}